import React from "react";
import Image from "../images/SoftwareImage.png";
import background from "../images/background.png";
import { CheckIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import WhyEntnt1 from "../images/WhyEntnt1.svg";
import WhyEntnt2 from "../images/WhyEntnt2.svg";
import Flexibility from "../images/Flexibility.svg"
import Partner from "../images/Partner.svg"
import WhyEntnt3 from "../images/WhyEntnt3.svg";
import WhyEntnt4 from "../images/WhyEntnt4.svg";
import Line from "../images/Line.svg";
import Timeline from "../images/image.png";
import en from "../translations/en.json";
import el from "../translations/el.json";

export const SmartShoring = ({ currentLang }: { currentLang: string }) => {
  const translation = { en, el };
  const t = (key: string) => {
    //  @ts-ignore
    return translation[currentLang]["smartshoring"][key];
  };
  return (
    <div className="px-2.5 md:px-28 lg:px-32 md:mt-24 mt-20 space-y-3 md:pb-20 pb-8">
      {/* section1 */}
      <div className="flex lg:flex-row flex-col-reverse rounded-tl-3xl bg-[#DAD7FF]">
        <div className="flex flex-col lg:justify-left lg:pl-24 lg:py-20 lg:w-1/2 px-10 lg:px-7 py-7">
          <div
            className={`flex gap-2 md:gap-4 ${
              currentLang === "el" ? "flex-col" : ""
            }`}
          >
            {" "}
            <p className="text-[#3B31DE] md:text-5xl text-2xl font-bold ">
              {t("hero-page-header-color1")}
            </p>
            <p className="text-black ml-0 md:text-5xl text-2xl font-bold ">
              {t("hero-page-header-color2")}
            </p>
          </div>
          <p className="text-black md:text-3xl text-xl text-left font-bold md:mt-3 mt-1">
            {t("hero-page-heading")}
          </p>
          <p className="text-black text-justify font-medium md:text-lg text-md mt-10">
            {t("hero-page-paragraph")}
            <br />
            <br />
            {t("hero-page-paragraph-2")}
            <br />
            <br />
            {t("hero-page-paragraph-3")}
            <br />
            {t("hero-page-paragraph-4")}
          </p>
          <div className="flex space-x-5 md:mt-12 mt-8 md:mb-0 mb-12 ">
           
          </div>
        </div>
        <div className=" lg:mr-2 lg:-mt-12 -mt-10 lg:w-1/2 lg:mb-20 px-7">
          <img
            src={Image}
            alt="software"
            className="rounded-2xl h-48 w-full md:rounded md:h-[90%] "
          />
        </div>
      </div>

      {/*section 2*/}
      <div className="flex lg:flex-row  flex-col bg-[#05005D] ">
        <div className=" lg:ml-10 lg:-mt-12 -mt-10 lg:fit lg:mb-20 lg:w-1/2 px-7">
          <img
            src={Timeline}
            alt="Timeline"
            className="rounded-2xl h-48 w-full md:h-full"
          />
        </div>
        <div className="flex flex-col lg:justify-end md:pr-20 md:pl-8 md:pb-16 lg:pt-10 lg:w-1/2 mt-5 p-10">
          <div>
            {" "}
            <p className="text-[#847CFF] md:text-4xl  text-2xl font-normal ">
              {t("main-content-heading-color1")}
            </p>
            <p className="text-white md:text-4xl text-2xl font-bold mt-2">
              {t("main-content-heading-color2")}
            </p>
          </div>

          <p className="text-white text-left font-normal md:mt-6 mt-4">
            {t("main-content-para")}
            <br />
            <br />
            <p className="mb-4">{t("main-content-list-header ")}</p>
            {currentLang === "en" ? (
              <ul className="list-disc  pl-5 mb-4">
                <li>{t("main-content-list-item1")}</li>
                <li>{t("main-content-list-item2")}</li>
                <li>{t("main-content-list-item3")}</li>
           
              </ul>
            ) : (
              ""
            )}

            <p className="mb-4">{t("main-content-list-footer")}</p>
          </p>

         
        </div>
      </div>

      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="  md:px-28 md:py-20 px-7 py-10 w-full"
      >
        <div>
          <p className="text-[#05005D] md:text-4xl text-2xl font-bold ">
            {t("service-title-color1")}
          </p>
          <p className="text-white md:text-4xl text-2xl font-bold  ">
            {t("service-title-color2")}
          </p>
        </div>
        <p className="md:mt-16 mt-4 text-white font-bold md:text-xl text-lg">
          {t("services-header")}
        </p>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4 lg:items-start md:text-lg text-md ">
          {[
            {
              title: t("services-list-item1-title"),
              description: t("services-list-item1-desc"),
            },
            {
              title: t("services-list-item2-title"),
              description: t("services-list-item2-desc"),
            },
         
           
          ].map((item, index) => (
            <div key={index} className={`flex space-x-2 mt-6 w-full  ""`}>
              <CheckIcon className={`h-28 w-28 -mt-9 text-[#3B31DE]  "" `} />
              <div className="text-white">
                <p className="font-medium text-[#05005D]">{item.title}</p>
                <p>{item.description}</p>
                {}
              </div>
            </div>
          ))}
        </div>
        <img className="md:mt-12 w-full h-1 mt-4" src={Line} alt="line" />
      </div>

      <div className="min-h-screen/2 p-4 sm:p-20 bg-gray-100 flex items-center justify-center">
        <div className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
            {t("experties-title")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* System Integration */}
            <div className="flex flex-col items-center bg-[#DAD7FF]  p-6 rounded-lg  ">
              <h3 className="text-lg text-justify font-semibold mb-3 text-gray-800">
                {t("experties-title-1")}
              </h3>
              <p className="text-gray-600 text-justify  text-center">
                {t("experties-desc-1")}
              </p>
            </div>

            {/* Legacy Modernization */}
            <div className="flex flex-col items-center bg-[#DAD7FF]  p-6 rounded-lg   ">
              <h3 className="text-lg font-semibold mb-3 text-gray-800 ">
                {t("experties-title-2")}
              </h3>
              <p className="text-gray-600 text-justify text-center">
                {t("experties-desc-2")}
              </p>
            </div>

            {/* New Development */}
            <div className="flex flex-col items-center bg-[#DAD7FF]  p-6 rounded-lg  ">
              <h3 className="text-lg font-semibold mb-3 text-gray-800">
                {t("experties-title-3")}
              </h3>
              <p className="text-gray-600 text-justify">
                {t("experties-desc-3")}
              </p>
            </div>

            {/* Support and Maintenance */}
            <div className="flex flex-col items-center bg-[#DAD7FF]  p-6 rounded-lg ">
              <h3 className="text-lg font-semibold mb-3 text-gray-800">
                {t("experties-title-4")}
              </h3>
              <p className="text-gray-600 text-justify">
                {t("experties-desc-4")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#DAD7FF]  p-8 pb-24">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
            {t("steps-title")}
          </h2>
          <p className="p-8 pb-16 text-justify"> {t("steps-desc")}</p>
          <div className="space-y-8">
            {/* Step 1 */}
            <div className=" p-6 rounded-md bg-[#05005D]">
              <h3 className="text-xl font-semibold text-white">
                {t("steps-title-step1")}
              </h3>
              <p className="mt-4 font-semibold text-justify  text-white ">
                {t("steps-desc-step1")}
              </p>
              <ul className="list-disc list-inside mt-4 text-gray-100">
                <li> {t("step1-list1")}</li>
                <li>{t("step1-list2")}</li>
                <li>{t("step1-list3")}</li>
              </ul>
            </div>

            {/* Step 2 */}

         
            <div className="flex justify-center text-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m0 0l-4-4m4 4l4-4"
                />
              </svg>
            </div>

            <div className="bg-[#05005D] p-6  rounded-md">
              <h3 className="text-xl font-semibold text-white">
                {t("steps-title-step2")}
              </h3>
              <p className="mt-4 text-white text-justify ">{t("steps-desc-step2")}</p>
              <ul className="list-disc  list-inside mt-4 text-gray-100">
                <li>{t("step2-list1")}</li>
                <li>{t("step2-list2")}</li>
                <li>{t("step2-list3")}</li>
              </ul>
            </div>

            {/* Step 3 */}
            <div className="flex justify-center text-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m0 0l-4-4m4 4l4-4"
                />
              </svg>
            </div>

            <div className="bg-[#05005D] p-6  rounded-md">
              <h3 className="text-xl font-semibold text-white">
                {t("steps-title-step3")}
              </h3>
              <p className="mt-4 text-white text-justify">{t("steps-desc-step3")}</p>
              <ul className="list-disc list-inside mt-4 text-gray-100">
                <li>{t("step3-list1")}</li>
                <li>{t("step3-list2")}</li>
              </ul>
            </div>

            {/* Step 4 */}

            <div className="flex justify-center text-gray-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m0 0l-4-4m4 4l4-4"
                />
              </svg>
            </div>

            <div className="bg-[#05005D] p-6  rounded-md">
              <h3 className="text-xl font-semibold text-white">
                {t("steps-title-step4")}
              </h3>
              <p className="mt-4 text-gray-100 text-justify ">{t("steps-desc-step4")}</p>
              <ul className="list-disc list-inside mt-4 text-gray-100">
                <li>{t("step4-list1")}</li>
                <li>{t("step4-list2")}</li>
                <li>{t("step4-list3")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*section 3*/}
   { currentLang === "en" ? 
      <div>
        <div>
          <p className="text-black font-bold md:text-4xl text-2xl text-center md:mt-20 mt-10 md:uppercase">
            Why ENTNT?
          </p>

          <div className="flex flex-wrap lg:flex-nowrap space-x-2 justify-center md:mt-20 mt-16">
            <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[500px] mb-20 rounded-lg relative">
              <img
                className="h-16 w-16 absolute -top-10 bg-[#7064FF] rounded-xl"
                src={Flexibility}
                alt="why entnt1"
              />
              <p className="md:text-lg text-md font-bold text-center pt-10">
                {t("cta-list-item1-title")}
              </p>
              <p className="text-md text-justify">{t("cta-list-item1-desc")}</p>
            </div>
            <div className="flex flex-col justify-center items-center space-y-6 bg-[#F6F7F8] md:px-12 px-6 pb-10 max-w-[500px] mb-20 rounded-lg relative">
              <img
                className="h-16 w-16 absolute -top-10 bg-[#7064FF] rounded-xl"
                src={Partner}
                alt="why entnt2"
              />
              <p className="md:text-lg text-md font-bold text-center pt-10">
                {t("cta-list-item2-title")}{" "}
              </p>
              <p className="text-md text-justify">{t("cta-list-item2-desc")}</p>
            </div>
           
          </div>
        </div>
        <div className="min-h-screen/2 p-4 sm:p-20 bg-gray-100 flex items-center justify-center">
          <div className="container mx-auto px-4 py-12">
            <h2 className="text-2xl font-bold text-center mb-8 text-gray-800">
              {t("need-list-title")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 justify-between">
             
              <div className="flex flex-col items-center bg-[#DAD7FF]  p-6 rounded-lg  ">
                <p className="text-gray-900  text-1xl text-justify">
                  {t("need-list-item1-title")}
                </p>
              </div>

      
              <div className="flex flex-col items-center bg-[#DAD7FF]  p-6 rounded-lg   ">
                <p className="text-gray-900  text-1xl  text-justify">
                  {t("need-list-item2-title")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <section className="bg-gray-100 py-12 mt-8 p-10 sm:p-40">

      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Μπορούμε να βοηθήσουμε
        </h2>
        <div className="text-lg text-gray-700 space-y-6 text-justify">
          <p >
            Συνδυάζουμε τα πλεονεκτήματα του <span className="font-semibold">“On-the-Ground Project/Account Management”</span> με εκείνα της <span className="font-semibold">“Offshore Development Team”</span>.
          </p>
          <p>
            Διαθέτουμε υψηλής εξειδίκευσης επαγγελματίες, έτοιμους να ανταποκριθούν στις ανάγκες σας.
          </p>
          <p>
            Οι διαδικασίες μας είναι σχεδιασμένες για να καλύπτουν απόλυτα τις απαιτήσεις σας.
          </p>
          <p>
            Παρέχουμε πλήρη ευελιξία στην έναρξη και την ολοκλήρωση της συνεργασίας μας, χωρίς καμία δέσμευση από την πλευρά σας.
          </p>
          <p>
            Σας προσφέρουμε την ελευθερία να συμμετέχετε στη διαδικασία στον βαθμό που εσείς επιθυμείτε.
          </p>
        </div>
      </div>
    </section> }

      <section className="py-16 bg-[#05005D]">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8 text-white">
            <span className="text-[#847CFF]">{t("contact-header-color1")}</span>{" "}
            {t("contact-header-color2")}
          </h2>
          <p className="text-lg text-white font-medium mb-12 max-w-2xl mx-auto">
            {t("contact-para")}
          </p>
          <button
            className="inline-flex items-center bg-[#847CFF] text-white font-semibold py-3 px-6 gap-2 rounded-full transition-colors duration-300"
            onClick={() => (window.location.href = "mailto:contact@entnt.in")}
          >
            <EnvelopeIcon className="h-5 w-5" />
            {t("contact-button-title")}
          </button>
        </div>
      </section>
    </div>
  );
};
