import "./App.css";
import { Route, BrowserRouter as Router, Routes} from "react-router-dom";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Shipping } from "./components/Shipping";
import { Software } from "./components/Software";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndCondition";
import { useEffect, useState } from "react";

import { SmartShoring } from "./components/SmartShoring";

function App() {
  const [currentLang, setCurrentLang] = useState<string>("en");
  useEffect(() => {
    const detectLanguage = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        
        if (data.country_code === 'GR') {
          setCurrentLang('el')
        } else {
          const browserLang = navigator.language.split('-')[0]
          setCurrentLang(browserLang === 'el' ? 'el' : 'en')
        }
      } catch (error) {
        console.error('Error detecting language:', error)
        const browserLang = navigator.language.split('-')[0]
        setCurrentLang(browserLang === 'el' ? 'el' : 'en')
      }
    }

    detectLanguage()
  }, []);

  return (
    <div className="">
      <Header setCurrentLang={(lang: string) => { setCurrentLang(lang)}} currentLang={currentLang}/>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/shipping" element={<Shipping currentLang={currentLang}/>}></Route>
          <Route path="/software" element={<Software currentLang={currentLang}/>}></Route>
          <Route path="/smartshoring" element={<SmartShoring currentLang={currentLang}/>}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
